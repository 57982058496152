const userMessages = {
  fields: {
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'E-mail',
    password: 'Mot de passe',
    phone: 'Téléphone',
    codePostal: 'Code postal',
    category: 'Catégorie',
    otherCategory: 'Description de catégorie',
    distributorBrand: 'Enseigne',
    agency: 'Agence',
    codeAgency: 'Code agence',
    companyName: 'Entreprise / Raison sociale',
    roleInCompany: 'Rôle / Fonction',
    confirmPassword: 'Confirmation du mot de passe',
    categories: {
      artisan: 'Artisan / Entreprise',
      bureauControle: 'Bureau de contrôle',
      collabCAPEB: 'Collaborateur de la CAPEB',
      collabFFB: 'Collaborateur de la FFB',
      conseiller: 'Conseiller ou Accompagnateur France Rénov’',
      distributor: 'Distributeur Matériaux / Matériels',
      eligible: 'Éligible',
      formateur: 'Formateur / Organisme de Formation',
      mandataire: 'Mandataire',
      oblige: 'Obligé/Délégataire',
      other: 'Autre'
    }
  },
  home: {
    title: 'Déroulé de la formation',
    userDataDrawer: {
      showDetailButton: 'Voir le detail'
    },
    progressBlock: {
      title: 'Ma progression',
      start: 'Commencer la formation',
      resume: 'Reprendre la formation',
      goToFinalTest: 'Passer le test final',
      success1: 'Vous avez complété tous les modules\xa0!',
      success2: 'Il ne vous reste plus qu’à <b>réussir le test de fin pour valider la formation&nbsp;:</b>',
      finished1: 'Vous avez validé la formation.',
      finished2: 'N’oubliez pas que vous pouvez revoir les cours et rejouer les activités quand vous le souhaitez\xa0!'
    },
    finalTest: {
      name: 'Test final',
      label: 'Valider la formation'
    }
  },
  login: {
    title: 'Me connecter à la @:global.appName',
    label: 'Connexion',
    button: 'Me connecter',
    forgotPassword: 'Je n’arrive pas à me connecter',
    register: 'Vous n’avez pas de compte\xa0?',
    registerMessage: 'Inscrivez-vous en quelques minutes et commencez la formation\xa0!',
    errors: {
      password: 'Merci de saisir un mot de passe',
      noUser: 'Le mot de passe ou l\'email saisi est incorrect',
      otherError: 'Une erreur est survenue lors de la connexion'
    }
  },
  register: {
    title: 'M’inscrire à la @:global.appName',
    label: 'Formulaire d’inscription',
    confirmTitle: 'Valider mon inscription à la @:global.appName',
    confirmPreRegisterBlock: {
      title: 'Consultez vos emails\xa0!',
      content: 'Un lien vous a été envoyé par email afin de valider votre adresse email ',
      email: '({email})',
      help: 'Si vous n’avez rien reçu d’ici 2 minutes, merci de vérifier dans vos spams ou votre courrier indésirable.\n' +
        'Si vous rencontrez un problème et que vous n’arrivez pas à accéder à l’email, merci de nous écrire ',
      link: 'via notre site internet'
    },
    confirmRegisterBlock: {
      title: 'Votre inscription est terminée\xa0!',
      content: 'Vous pouvez dès à présent vous connecter pour commencer à suivre la formation @:global.oscar'
    },
    guestsBlock: {
      title: 'Collaborateurs CAPEB, FFB, et membres de réseaux de distribution\xa0:',
      content: 'merci de demander votre pré-inscription auprès de votre réseau. Pour toute question, merci de nous écrire via ',
      link: 'notre site internet.'
    },
    errors: {
      confirmPassword: 'Merci de saisir deux mots de passe identiques',
      emailAlreadyExist: 'Un compte existe déjà avec cette adresse e-mail',
      otherError: 'Une erreur est survenue lors de l\'inscription',
      invalidField: 'Une erreur est survenue lors de l\'inscription : Le formulaire est invalide',
      expiredToken: 'Une erreur est survenue : La validation de votre e-mail à expirée, veuillez recommencer le parcours d\'inscription',
      alreadyRegistered: 'Une erreur est survenue lors de l\'inscription : Votre inscription est déjà terminée, vous pouvez dès à présent vous connecter'
    },
    registerForm: {
      title: 'Votre email est validé\xa0!',
      help: 'Veuillez renseigner les informations suivantes pour terminer votre inscription.',
      legals: {
        rgpdLabel: 'Conditions d’utilisation et vie privée',
        rgpdCheck: "En cochant cette case, je reconnais avoir pris connaissance des conditions liées à l’utilisation de mes données et je consens à ce que mon adresse mail soit utilisée pour recevoir des informations sur @:{'global.oscar'}.",
        rgpdText: "Conformément à la loi Informatique et Libertés du 6 janvier 1978 modifiée, vous disposez d’un droit d’accès, de rectification, d’opposition et de suppression des données collectées vous concernant. Vous pouvez exercer ces droits par courrier électronique au responsable de traitement (ATEE) à l’adresse {'webmaster@atee.fr'}. Pour plus d’informations, consultez notre politique de protection des données personnelles."
      }
    }
  },
  forgotPassword: {
    title: 'Je n\'arrive pas à me connecter',
    label: 'Mot de passe oublié',
    newPwdLabel: 'Création d\'un nouveau mot de passe',
    emailLabel: 'E-mail du compte',
    help: 'Veuillez saisir votre adresse mail, un message vous sera envoyé pour créer un nouveau mot de passe.',
    confirmTitle: 'Consultez vos emails\xa0!',
    confirmContent: 'Un lien vous à été envoyé par e-mail ({email}) afin de définir un nouveau mot de passe.',
    resetPwdSuccess: 'Votre nouveau mot de passe a été crée avec succès',
    errors: {
      noUser: 'Aucun compte n\'existe pour cette adresse e-mail',
      accountNotActivated: 'Votre inscription n\'est pas terminée, consultez vos mails pour finaliser la création de votre compte',
      otherError: 'Une erreur est survenue lors de la réinitialisation de votre mot de passe',
      invalidPwd: 'Une erreur est survenue : mot de passe invalide',
      resetPwdTokenExpired: 'Une erreur est survenue : Le lien de réinitialisation du mot de passe à expiré.',
      otherErrorNewPwd: 'Une erreur est survenue lors de la création de votre nouveau mot de passe'
    }
  },
  edit: {
    title: 'Modifier mes informations',
    pwdTitle: 'Modifier mon mot de passe',
    editSuccess: 'Vos nouvelles informations ont bien été sauvegardées',
    errors: {
      invalidField: 'Une erreur est survenue lors de la modification : Le champ {field} est invalide',
      otherError: 'Une erreur est survenue lors de la modification'
    },
    password: {
      title: 'Modifier mon mot de passe',
      label: 'Nouveau mot de passe',
      success: 'Votre mot de passe à été modifié avec succès',
      errors: {
        otherError: 'Une erreur est survenue lors de la modification du mot de passe'
      }
    },
    deleteModal: {
      title: 'Demande de suppression de compte',
      button: 'Supprimer mon compte',
      reason: 'Raison de la suppression',
      content: 'La suppression de votre compte supprime toutes les données du compte.',
      content2: 'Votre demande sera traitée manuellement par notre équipe.',
      content3: 'Merci de nous indiquer la raison pour laquelle vous souhaitez supprimer votre compte.',
      confirm: 'Demander la suppression du compte',
      success: 'Votre demande de suppression à bien été prise en compte\xa0!',
      errorDuplicate: 'Une demande de suppression de votre compte est déjà en cours de traitement\xa0!',
      error: 'Une erreur est survenue lors de la demande de suppression\xa0!'
    }
  }
}

export default userMessages
